import ERC20 from './abi/ERC20.json'
import yVaultV1 from './abi/yVaultV1.json'
import CurveLP from './abi/CurveLP.json'

import Web3 from 'web3'
let web3 = new Web3(Web3.givenProvider);

const options = {
  web3: {
    block: false,
  },
  syncAlways: true,
  contracts: [
    {
      contractName: 'YFI',
      web3Contract: new web3.eth.Contract(ERC20, "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e")
    },
    {
      contractName: 'y3CRV',
      web3Contract: new web3.eth.Contract(yVaultV1, "0x9cA85572E6A3EbF24dEDd195623F188735A5179f")
    },
    {
      contractName: 'y3CRVCurve',
      web3Contract: new web3.eth.Contract(CurveLP, "0xbEbc44782C7dB0a1A60Cb6fe97d0b483032FF1C7")
    },
    {
      contractName: 'yUSD',
      web3Contract: new web3.eth.Contract(yVaultV1, "0x5dbcF33D8c2E976c6b560249878e6F1491Bca25c")
    },
    {
      contractName: 'yUSDCurve',
      web3Contract: new web3.eth.Contract(CurveLP, "0x45f783cce6b7ff23b2ab2d70e416cdb7d6055f51")
    },
  ],
  events: {
  },
  polls: {
    accounts: 10000
  }
}

export default options
