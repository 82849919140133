<template lang="pug">
#prices(v-if="isDrizzleInitialized")
  .logo 💲🏷
  h1.title.is-3 Price Tags
  div.tile.is-ancestor
    div.tile.is-3.is-parent

      div.tile.is-child.price-box
        div.content
          b-image(
            :src="y3crv_image"
          )
          p y3CRV: {{ y3crv_dollar | toCurrency(decimal_precision) }} 
          a(
            href="#"
            @click.prevent="on_add_token_y3crv"
          ) Add to Metamask
          
      div.tile.is-child.price-box
        div.content
          b-image(
            :src="yusd_image"
          )
          p yUSD: {{ yusd_dollar | toCurrency(decimal_precision) }} 
          a(
            href="#"
            @click.prevent="on_add_token_yusd"
          ) Add to Metamask
</template>

<script>

import ethers from "ethers";
import { mapGetters } from "vuex";
import moment from 'moment';

import Web3 from "web3";

let web3 = new Web3(Web3.givenProvider);

const max_uint = new ethers.BigNumber.from(2).pow(256).sub(1).toString();
const BN_ZERO = new ethers.BigNumber.from(0);
const ADDRESS_ZERO = "0x0000000000000000000000000000000000000000";

const DECIMAL_PRECISION = 4;
const ERROR_CLIFF_NOT_OVER = "Cliff is not over. You have to wait 😓";
const ERROR_NEGATIVE_ALL = "You have to claim more than 0 🤓";
const TO_FLOAT = new ethers.BigNumber.from(10).pow(36-DECIMAL_PRECISION).toString();

export default {
  name: "Prices",
  components: {
  },
  data() {
    return {
      username: null,
      amount: 0,
      error: null,
      yfi_price: 0,
      decimal_precision: DECIMAL_PRECISION,
    };
  },
  filters: {
    fromWei(data, precision, decimals) {
      if (decimals === undefined) decimals = 18;
      if (data === "loading") return data;
      if (data > 2 ** 255) return "♾️";
      let value = ethers.utils.commify(ethers.utils.formatUnits(data, decimals));
      let parts = value.split(".");

      if (precision === 0) return parts[0];

      return parts[0] + "." + parts[1].slice(0, precision);
    },
    toPct(data, precision) {
      if (isNaN(data)) return "-";
      return `${(data * 100).toFixed(precision)}%`;
    },
    toCurrency(data, precision) {
      if ( !data ) return "-";
      
      if (typeof data !== "number") {
        data = parseFloat(data);
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: precision,
      });
      return formatter.format(data);
    },
    fromUnix(data) {
            return moment.unix(data).format('MMMM Do YYYY, HH:mm:ss');
    },
    fromSeconds(data) {
            return moment(data).format('MMMM Do YYYY, HH:mm:ss');
    }
  },
  methods: {
    async load_reverse_ens() {
      let lookup = this.activeAccount.toLowerCase().substr(2) + ".addr.reverse";
      let resolver = await this.drizzleInstance.web3.eth.ens.resolver(lookup);
      let namehash = ethers.utils.namehash(lookup);
      this.username = await resolver.methods.name(namehash).call();
    },
    call(contract, method, args, out = "number") {
      let key = this.drizzleInstance.contracts[contract].methods[
        method
      ].cacheCall(...args);
      let value;
      try {
        value = this.contractInstances[contract][method][key].value;
      } catch (error) {
        value = null;
      }
      switch (out) {
        case "number":
          if (value === null) value = 0;
          return new ethers.BigNumber.from(value);
        case "address":
          return value;
        default:
          return value;
      }
    },
    on_add_token_y3crv() {
      window.ethereum.request({
        method: 'wallet_watchAsset',
            params: {
              'type': 'ERC20',
              'options': {
                'address': this.y3crv_address,
                'symbol': 'y3CRV',
                'decimals': 18,
                'image': "https://raw.githubusercontent.com/iearn-finance/yearn-assets/master/icons/tokens/"+this.y3crv_address+"/logo.svg",
              },
            }
        })
    },
    on_add_token_yusd() {
      window.ethereum.request({
        method: 'wallet_watchAsset',
            params: {
              'type': 'ERC20',
              'options': {
                'address': this.yusd_address,
                'symbol': 'yUSD',
                'decimals': 18,
                'image': "https://raw.githubusercontent.com/iearn-finance/yearn-assets/master/icons/tokens/"+this.yusd_address+"/logo.svg",
              },
            }
        })
    },
  },
  computed: {
    ...mapGetters("accounts", ["activeAccount", "activeBalance"]),
    ...mapGetters("drizzle", ["drizzleInstance", "isDrizzleInitialized"]),
    ...mapGetters("contracts", ["getContractData", "contractInstances"]),

    user() {
      return this.activeAccount;
    },
    y3crv_address() {
      return this.drizzleInstance.contracts["y3CRV"].address;
    },
    y3crv_image() {
      return "https://raw.githubusercontent.com/iearn-finance/yearn-assets/master/icons/tokens/"+this.y3crv_address+"/logo.svg";
    },
    y3crv_price_per_share() {
      return this.call("y3CRV", "getPricePerFullShare", []);
    },
    y3crv_virtual_price() {
      return this.call("y3CRVCurve", "get_virtual_price", []);
    },
    y3crv_dollar() {
      return this.y3crv_price_per_share.mul(this.y3crv_virtual_price).div(TO_FLOAT).toNumber()/10000;
    },
    yusd_address() {
      return this.drizzleInstance.contracts["yUSD"].address;
    },
    yusd_image() {
      return "https://raw.githubusercontent.com/iearn-finance/yearn-assets/master/icons/tokens/"+this.yusd_address+"/logo.svg";
    },
    yusd_price_per_share() {
      return this.call("yUSD", "getPricePerFullShare", []);
    },
    yusd_virtual_price() {
      return this.call("yUSDCurve", "get_virtual_price", []);
    },
    yusd_dollar() {
      return this.yusd_price_per_share.mul(this.yusd_virtual_price).div(TO_FLOAT).toNumber()/10000;
    },
  },
  async created() {
    
    //Active account is defined?
    if (this.activeAccount !== undefined) this.load_reverse_ens();

  },
};
</script>

<style>
input {
  height: 26px;
}
.muted {
  color: gray;
  font-size: 0.8em;
}
.muted a {
  text-decoration: underline;
}
.red {
  color: red;
  font-weight: 700;
}
.blue {
  color: blue;
  font-weight: 700;
}
.spacer {
  padding-top: 1em;
  padding-bottom: 1em;
}
a,
a:visited,
a:hover {
  color: gray !important;
}
a:hover {
  text-decoration: underline;
}
.price-box {
  text-align: center;
}
div.notFound {
	width: 900px;
	height: 200px;
	margin: auto;
  text-align: center;
  font-size: 3em;
}
</style>
